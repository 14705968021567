import "./clients/sentry.ts";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "./i18n";
import { ClerkProvider } from "@clerk/clerk-react";
import RelayProviderWrapper from "@/modules/common/providers/RelayProviderWrapper.tsx";
import ErrorFallback from "@/modules/common/components/ErrorFallback.tsx";
import * as Sentry from "@sentry/react";

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
    <RelayProviderWrapper>
      <React.StrictMode>
        <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog={false}>
          <App />
        </Sentry.ErrorBoundary>
      </React.StrictMode>
    </RelayProviderWrapper>
  </ClerkProvider>,
);
