import { ReactNode } from "react";
import { useAuth } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { isSignedIn } = useAuth();

  return isSignedIn ? children : <Navigate to="/sign-in" />;
};

export default PrivateRoute;
