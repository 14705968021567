import { SignIn } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";
import Logo from "@/modules/common/components/Logo.tsx";
import AuthPageMarketing from "@/modules/auth/components/AuthPageMarketing.tsx";
import { useTranslation } from "react-i18next";
import { ThemeToggle } from "@/modules/appearance/components/ThemeToggle.tsx";
import { useAppliedTheme } from "@/modules/appearance/hooks.ts";

const SignInPage = () => {
  const theme = useAppliedTheme();

  const { t } = useTranslation();

  return (
    <>
      <div className="container relative flex-col h-full items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0 bg-secondary ">
        <div className="hidden md:relative h-full max-h-[100vh] flex-col lg:flex">
          <div className="absolute inset-0 bg-background border" />
          <div className="relative z-20 flex items-center text-lg font-medium px-10 pt-10">
            <Logo />
          </div>
          <div className={"relative z-20 mt-auto mr-auto pe-1"}>
            <AuthPageMarketing />
          </div>
          <div className="relative z-20 mt-auto ">
            <blockquote className="space-y-2 p-10">
              <p className="text-lg">
                {t("Gaming audio rooms for you and your friends 🎧")}
              </p>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <SignIn
              signUpUrl={"/sign-up"}
              appearance={{
                baseTheme: theme === "dark" ? dark : undefined,
                variables: {
                  colorPrimary: "#ff00bf",
                },
              }}
            />
          </div>
        </div>
        <div className={"fixed top-5 right-5"}>
          <ThemeToggle />
        </div>
      </div>
    </>
  );
};

export default SignInPage;
