import { Label } from "@/components/ui/label.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { Field, FieldProps } from "formik";
import { EditProfileField } from "@/modules/profile/editProfile/constants.ts";
import { useTranslation } from "react-i18next";

const BioField = () => {
  const { t } = useTranslation();

  return (
    <Field name={EditProfileField.Bio}>
      {({ field }: FieldProps) => (
        <div>
          <Label htmlFor="about-me" className="text-right">
            {t("About me")}
          </Label>
          <Textarea
            placeholder="Tell us a little bit about yourself..."
            className="resize-none border"
            {...field}
            maxLength={190}
          />
        </div>
      )}
    </Field>
  );
};

export default BioField;
