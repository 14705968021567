import { graphql } from "relay-runtime";

export const CompleteProfileMutation = graphql`
  mutation CompleteProfileMutation($input: CompleteProfileInput!) {
    completeProfile(input: $input) {
      ... on Error {
        message
      }
      ... on MutationCompleteProfileSuccess {
        data {
          isProfileComplete
          fullUsername
          avatar
          name
        }
      }
    }
  }
`;
