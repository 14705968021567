export enum SettingsTab {
  PROFILE = "PROFILE",
  APPEARANCE = "APPEARANCE",
  LANGUAGE = "LANGUAGE",
  FEEDBACK = "FEEDBACK",
  DANGER_ZONE = "DANGER_ZONE",
}

export const SettingsTabs = {
  [SettingsTab.PROFILE]: {
    label: "Profile",
    value: SettingsTab.PROFILE,
  },
  [SettingsTab.APPEARANCE]: {
    label: "Appearance",
    value: SettingsTab.APPEARANCE,
  },
  [SettingsTab.LANGUAGE]: {
    label: "Language",
    value: SettingsTab.LANGUAGE,
  },
  [SettingsTab.FEEDBACK]: {
    label: "Feedback",
    value: SettingsTab.FEEDBACK,
  },
  [SettingsTab.DANGER_ZONE]: {
    label: "Danger zone",
    value: SettingsTab.DANGER_ZONE,
  },
};

export const AppearanceOptions = [
  { value: "light", label: "Light" },
  { value: "dark", label: "Dark" },
  { value: "system", label: "System" },
];

export enum FeedbackField {
  Name = "name",
  Email = "email",
  Message = "message",
}

export type FeedbackFormValues = {
  [FeedbackField.Name]: string;
  [FeedbackField.Email]: string;
  [FeedbackField.Message]: string;
};
