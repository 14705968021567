import { useTheme } from "@/modules/common/providers/ThemeProvider.tsx";

export const useAppliedTheme = () => {
  const { theme } = useTheme();
  const prefersDarkMode = window.matchMedia(
    "(prefers-color-scheme: dark)",
  ).matches;

  return theme === "system" ? (prefersDarkMode ? "dark" : "light") : theme;
};
