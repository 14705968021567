import { object, string } from "yup";

export enum EditProfileField {
  Name = "name",
  Bio = "bio",
  Avatar = "avatar",
}

export type FormValues = {
  [EditProfileField.Name]: string;
  [EditProfileField.Bio]: string;
  [EditProfileField.Avatar]: string | null | undefined;
};

export const validationSchema = object({
  name: string().min(1).max(30).required("Name is required"),
  bio: string().max(190),
  avatar: string().nullable(),
});
