import { Label } from "@/components/ui/label.tsx";
import { useTranslation } from "react-i18next";
import { Field, FieldProps } from "formik";
import { CompleteProfileField } from "@/modules/completeProfile/types.ts";

const BirthdayField = () => {
  const { t } = useTranslation();

  return (
    <div className="grid w-full max-w-sm items-center gap-1.5">
      <Label htmlFor="birthday">{t("Birthday")}</Label>
      <Field name={CompleteProfileField.Birthday}>
        {({ meta, field }: FieldProps) => (
          <>
            <input
              type="date"
              className="bg-gray-50 border bg-transparent text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Select date"
              {...field}
            />
            {meta.error && meta.touched && (
              <small className="text-sm font-medium leading-none text-red-500">
                {t(meta.error)}
              </small>
            )}
          </>
        )}
      </Field>
    </div>
  );
};

export default BirthdayField;
