import { SettingsTab, SettingsTabs } from "@/modules/settings/constants.ts";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { Button } from "@/components/ui/button.tsx";

const SettingsNavigation = (props: {
  activeTab: SettingsTab;
  onTabChange: (tab: SettingsTab) => any;
}) => {
  const renderButton = (tab: { label: string; value: SettingsTab }) => {
    return (
      <Button
        key={tab.value}
        variant={props.activeTab === tab.value ? "secondary" : "ghost"}
        className="w-full md:justify-start px-6 py-2 h-10  flex items-center justify-center"
        onClick={() => props.onTabChange(tab.value)}
      >
        {tab.label}
      </Button>
    );
  };

  return (
    <>
      <div className={"sm:hidden bg-background"}>
        <ScrollArea>
          <div className="w-full relative h-12 overflow-x-scroll">
            <div className="flex absolute px-3">
              {Object.values(SettingsTabs).map((tab) => renderButton(tab))}
            </div>
          </div>
        </ScrollArea>
      </div>
      <div className="flex-1 flex-row sm:flex-col md:p-2 bg-background pb-5 md:pb-0 hidden sm:flex px-4">
        {Object.values(SettingsTabs).map((tab) => renderButton(tab))}
      </div>
    </>
  );
};

export default SettingsNavigation;
