import { Label } from "@/components/ui/label.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useTranslation } from "react-i18next";
import { Field, FieldProps } from "formik";
import { EditProfileField } from "@/modules/profile/editProfile/constants.ts";

const NameField = () => {
  const { t } = useTranslation();

  return (
    <Field name={EditProfileField.Name}>
      {({ field, meta }: FieldProps) => (
        <div>
          <Label htmlFor="name" className="text-right">
            {t("Name")}
          </Label>
          <Input
            {...field}
            maxLength={30}
            placeholder={t("Enter your display name...")}
            className={"border"}
          />
          {meta.error && (
            <small className="text-sm font-medium leading-none text-red-500">
              {t(meta.error)}
            </small>
          )}
        </div>
      )}
    </Field>
  );
};

export default NameField;
