import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button.tsx";

const Error = () => {
  const { t } = useTranslation();

  return (
    <div className={"h-full bg-secondary flex  justify-center items-center"}>
      <div
        className={
          "bg-background rounded-lg p-5 border flex flex-col items-center max-w-md"
        }
      >
        <div className={"text-3xl my-2"}>😢</div>
        <div className={"font-bold text-xl text-center"}>
          {t("Error occurred")}
        </div>
        <div className={"text-gray-500 mb-4 text-center"}>
          {t(
            "An unexpected error occurred, please reload the page or try again later...",
          )}
        </div>
        <Button onClick={() => location.reload()}>{t("Reload Page")}</Button>
      </div>
    </div>
  );
};

export default Error;
