import { Button } from "@/components/ui/button.tsx";
import { useTranslation } from "react-i18next";
import { ChangeEvent, LegacyRef, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import Cropper, { Area } from "react-easy-crop";
import { toast } from "@/components/ui/use-toast.ts";
import Compressor from "compressorjs";
import { AVATAR_SIZE_LIMIT_IN_MB } from "@/modules/common/constants.ts";
import getCroppedImg, {
  convertBytesToMB,
  toBase64,
} from "@/modules/common/utils.ts";
import { Slider } from "@/components/ui/slider.tsx";
import { graphql } from "relay-runtime";
import { useMutation } from "react-relay";
import { Loader2 } from "lucide-react";
import type { ImagePickerUploadImageMutation as ImagePickerUploadImageMutationType } from "@/__generated__/ImagePickerUploadImageMutation.graphql.ts";

const ImagePickerUploadImageMutation = graphql`
  mutation ImagePickerUploadImageMutation($image: String!, $type: ImageType!) {
    uploadImage(image: $image, type: $type) {
      __typename
      ... on Error {
        message
      }
      ... on MutationUploadImageSuccess {
        data
      }
    }
  }
`;

const ImagePicker = ({
  title,
  onImageSelect,
}: {
  title: string;
  onImageSelect: (url: string) => void;
}) => {
  const inputFile: LegacyRef<HTMLInputElement> | undefined = useRef(null);

  const { t } = useTranslation();
  const [commitMutation, isMutationInFlight] =
    useMutation<ImagePickerUploadImageMutationType>(
      ImagePickerUploadImageMutation,
    );

  const [selectedFile, setSelectedFile] = useState<File | Blob | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  //file selected
  const fileSelectedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];

      //check if not jpg
      if (
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/webp"
      ) {
        toast({
          variant: "destructive",
          title: t("Format not supported"),
        });
        event.target.value = "";
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        setSelectedFile(file);

        //compress image(non-gif)
        new Compressor(file, {
          quality: 0.8,
          convertTypes: ["image/png", "image/webp"],
          success(result) {
            if (convertBytesToMB(result.size) > AVATAR_SIZE_LIMIT_IN_MB) {
              setSelectedFile(null);
              event.target.value = "";
              return;
            }
            setSelectedFile(result);
            event.target.value = "";
          },
          error() {
            toast({
              variant: "destructive",
              title: t("Something went wrong, try again later."),
            });
            event.target.value = "";
          },
        });
      };
    }
  };

  //apply crop and upload
  const applyCrop = async () => {
    if (croppedAreaPixels && preview) {
      const croppedImage = (await getCroppedImg(
        preview,
        croppedAreaPixels,
        rotation,
      )) as Blob;

      commitMutation({
        variables: {
          image: (await toBase64(croppedImage)) as string,
          type: "AVATAR",
        },
        onCompleted: (response) => {
          if (response.uploadImage?.__typename === "Error") {
            toast({
              variant: "destructive",
              title: t(response.uploadImage.message || "Something went wrong"),
            });
            return;
          }
          if (
            response.uploadImage?.__typename === "MutationUploadImageSuccess"
          ) {
            setSelectedFile(null);
            if (response.uploadImage?.data) {
              onImageSelect(response.uploadImage.data);
            } else {
              setSelectedFile(null);
              toast({
                variant: "destructive",
                title: t("Something went wrong, try again later."),
              });
            }
          }
        },
        onError: () => {
          setSelectedFile(null);
          toast({
            variant: "destructive",
            title: t("Something went wrong, try again later."),
          });
        },
      });
    }
  };

  useEffect(() => {
    let objectUrl: string;

    if (selectedFile) {
      // create the preview
      objectUrl = URL.createObjectURL(selectedFile);

      setPreview(objectUrl);
    }

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <>
      <input
        type="file"
        alt="avatar"
        accept="image/*"
        onChange={fileSelectedHandler}
        style={{
          display: "none",
        }}
        ref={inputFile}
      />
      <Button
        type={"button"}
        onClick={() => {
          inputFile.current?.click();
        }}
      >
        {t(title)}
      </Button>
      <Dialog
        open={Boolean(selectedFile)}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setSelectedFile(null);
          }
        }}
      >
        <DialogContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          className={"min-w-[300px] max-w-[500px]"}
        >
          <DialogTitle>{t("Edit Image")}</DialogTitle>
          <div
            className={
              "relative w-auto h-[400px] flex items-center justify-center rounded-md overflow-hidden"
            }
          >
            <Cropper
              image={preview || undefined}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={false}
              cropShape={"round"}
              objectFit="horizontal-cover"
              style={{
                containerStyle:
                  selectedFile?.type === "image/gif"
                    ? {
                        pointerEvents: "none",
                        cursor: "default",
                      }
                    : {},
                cropAreaStyle: {
                  display: selectedFile?.type === "image/gif" ? "none" : "flex",
                  border: "4px solid white",
                },
              }}
            />
          </div>

          <DialogFooter>
            <div className={"flex flex-col w-full gap-4"}>
              <div className={"flex flex-col items-center"}>
                <div className={"w-[90%]"}>
                  <div className={"text-start mb-3"}>{t("Zoom")}</div>
                  <Slider
                    min={1}
                    max={3}
                    step={0.1}
                    value={[zoom]}
                    onValueChange={(newZoom) => setZoom(newZoom[0])}
                  />
                </div>
              </div>
              <div className={"flex flex-col items-center"}>
                <div className={"w-[90%]"}>
                  <div className={"text-start mb-3"}>{t("Rotate")}</div>
                  <Slider
                    min={0}
                    max={360}
                    step={0.1}
                    value={[rotation]}
                    onValueChange={(newRotation) => setRotation(newRotation[0])}
                  />
                </div>
              </div>
              <div className={"w-full flex justify-end mt-4"}>
                <Button
                  variant={"ghost"}
                  onClick={() => setSelectedFile(null)}
                  type={"button"}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  onClick={applyCrop}
                  disabled={isMutationInFlight}
                  type={"button"}
                  className={"flex gap-2"}
                >
                  {isMutationInFlight && (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  )}
                  {t("Apply")}
                </Button>
              </div>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImagePicker;
