import { DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";

import { useTranslation } from "react-i18next";

import { ReactNode } from "react";

const Section = ({ title, content }: { title: string; content: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <div className={"flex-1 flex flex-col"}>
      <DialogHeader className={"hidden sm:block mb-4"}>
        <DialogTitle>{t(title)}</DialogTitle>
      </DialogHeader>
      {content}
    </div>
  );
};

export default Section;
