/**
 * @generated SignedSource<<2c5c9eca335b1c7456a9d142d7b3610b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompleteProfilePageQuery$variables = Record<PropertyKey, never>;
export type CompleteProfilePageQuery$data = {
  readonly viewer: {
    readonly profile: {
      readonly isProfileComplete: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"CompleteProfileFormFragment_profile">;
    } | null | undefined;
  } | null | undefined;
};
export type CompleteProfilePageQuery = {
  response: CompleteProfilePageQuery$data;
  variables: CompleteProfilePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isProfileComplete",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompleteProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CompleteProfileFormFragment_profile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CompleteProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tag",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6cfba893f91859e9d23d65dbe04eaf49",
    "id": null,
    "metadata": {},
    "name": "CompleteProfilePageQuery",
    "operationKind": "query",
    "text": "query CompleteProfilePageQuery {\n  viewer {\n    profile {\n      isProfileComplete\n      ...CompleteProfileFormFragment_profile\n      id\n    }\n  }\n}\n\nfragment CompleteProfileFormFragment_profile on User {\n  tag\n  isProfileComplete\n}\n"
  }
};
})();

(node as any).hash = "b49c1b1ba1b2ed230db5db5fced7ce9f";

export default node;
