import { Button, buttonVariants } from "@/components/ui/button.tsx";
import { LogOut } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog.tsx";
import { useAuth } from "@clerk/clerk-react";
import { useTranslation } from "react-i18next";

const LogoutDialog = () => {
  const { t } = useTranslation();
  const { signOut } = useAuth();
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant={"ghost"} className={"w-full flex justify-start gap-3"}>
          <LogOut className="rtl:ml-2 ltr:mr-2 h-4 w-4" />
          {t("Log Out")}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("Log Out")}</AlertDialogTitle>
          <AlertDialogDescription>
            {t("Are you sure you want to logout?")}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("Cancel")}</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => signOut()}
            className={buttonVariants({ variant: "destructive" })}
          >
            {t("Log Out")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default LogoutDialog;
