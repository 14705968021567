import { date, object, string } from "yup";

export const validationSchema = object({
  name: string().required("Name is required"),
  username: string()
    .required("Username is required")
    .matches(
      /^[a-z][a-z0-9_.]{0,29}$/,
      "Invalid username (only lowercase letters, digits, dots, and underscores are allowed)",
    ),
  birthday: date()
    .required("Birthday is required")
    .test("age", "You must be at least 18 years old", function (value) {
      const cutoffDate = new Date();
      cutoffDate.setFullYear(cutoffDate.getFullYear() - 18);
      return value <= cutoffDate;
    }),
});
