export enum CompleteProfileField {
  Username = "username",
  Name = "name",
  Birthday = "birthday",
}

export type FormValues = {
  [CompleteProfileField.Username]: string;
  [CompleteProfileField.Name]: string;
  [CompleteProfileField.Birthday]: Date | undefined;
};
