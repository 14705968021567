import darkLogo from "../../../assets/dark-theme-logo.png";
import lightLogo from "../../../assets/light-theme-logo.png";
import { useAppliedTheme } from "@/modules/appearance/hooks.ts";

const Logo = () => {
  const theme = useAppliedTheme();

  return (
    <img
      className={"max-w-[120px]"}
      src={theme === "light" ? lightLogo : darkLogo}
      alt={"logo"}
    />
  );
};

export default Logo;
