import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Loader2, Trash2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-relay";

import { useAuth } from "@clerk/clerk-react";
import { toast } from "@/components/ui/use-toast.ts";
import { DeleteAccountMutation } from "@/modules/settings/graphql/DeleteAccountMutation.ts";
import type { DeleteAccountMutation as DeleteAccountMutationType } from "src/__generated__/DeleteAccountMutation.graphql.ts";

const DeleteAccount = () => {
  const auth = useAuth();
  const { t } = useTranslation();

  const [commitMutation, isMutationInFlight] =
    useMutation<DeleteAccountMutationType>(DeleteAccountMutation);

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant={"destructive"} className={"w-fit"}>
          <Trash2 className={"mx-2 h-4 w-4"} />
          {t("Delete account")}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("Delete account")}</AlertDialogTitle>
          <AlertDialogDescription>
            {t(
              "This action cannot be undone. This will permanently delete your account and remove your data from our servers.",
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("Cancel")}</AlertDialogCancel>
          <AlertDialogAction
            className={"bg-destructive hover:bg-destructive"}
            onClick={() =>
              commitMutation({
                variables: {},
                onCompleted: (response) => {
                  if (response.deleteAccount?.data === true) {
                    auth.signOut();
                  } else {
                    toast({
                      variant: "destructive",
                      title: t("Something went wrong, try again later"),
                    });
                  }
                },
              })
            }
            disabled={isMutationInFlight}
          >
            {isMutationInFlight && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            {t("Delete account")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteAccount;
