import Navbar from "@/modules/common/components/Navbar.tsx";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { DashboardQuery } from "@/modules/common/components/Dashboard/DashboardQuery.ts";
import type { DashboardQuery as DashboardQueryType } from "@/__generated__/DashboardQuery.graphql.ts";
import { useEffect } from "react";
import { DashboardProvider } from "@/modules/common/providers/DashboardContext.tsx";

const Dashboard = () => {
  const navigate = useNavigate();
  const preloadedQuery = useLoaderData() as PreloadedQuery<DashboardQueryType>;
  const data = usePreloadedQuery(DashboardQuery, preloadedQuery, {});

  useEffect(() => {
    if (
      data.viewer?.profile &&
      data.viewer?.profile?.isProfileComplete === false
    ) {
      navigate("/complete-profile");
    }
  }, [data, navigate]);

  return (
    <DashboardProvider value={{ data }}>
      <div className="h-screen w-screen flex flex-col">
        <div className="flex-1 flex">
          {/*<Sidebar />*/}
          <div className="flex-1 flex flex-col bg-secondary overflow-clip">
            <div className="h-full w-full bg-background border rounded-bl-sm rounded-br-sm">
              <Outlet />
            </div>
            <Navbar />
          </div>
        </div>
      </div>
    </DashboardProvider>
  );
};

export default Dashboard;
