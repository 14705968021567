import UserButton from "@/modules/common/components/UserButton.tsx";

const Navbar = () => {
  return (
    <div className={"w-full flex justify-end py-2 px-4 bg-secondary"}>
      <div className={"flex gap-5 items-center"}>
        <UserButton />
      </div>
    </div>
  );
};

export default Navbar;
