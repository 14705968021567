import { useLoaderData, useNavigate } from "react-router-dom";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import CompleteProfilePageQueryGraphql, {
  CompleteProfilePageQuery,
} from "@/__generated__/CompleteProfilePageQuery.graphql.ts";
import { useEffect } from "react";
import { CompleteProfileForm } from "@/modules/completeProfile/components/CompleteProfileForm.tsx";
import { CompleteProfileFormFragment_profile$key } from "@/__generated__/CompleteProfileFormFragment_profile.graphql.ts";

const CompleteProfilePage = () => {
  const navigate = useNavigate();
  const preloadedQuery =
    useLoaderData() as PreloadedQuery<CompleteProfilePageQuery>;

  const data = usePreloadedQuery(
    CompleteProfilePageQueryGraphql,
    preloadedQuery,
  );

  useEffect(() => {
    if (
      data.viewer?.profile &&
      data.viewer?.profile?.isProfileComplete === true
    ) {
      navigate("/complete-profile");
    }
  }, [data, navigate]);

  return (
    <CompleteProfileForm
      profile={data.viewer?.profile as CompleteProfileFormFragment_profile$key}
    />
  );
};

export default CompleteProfilePage;
