import { useAuth, useUser } from "@clerk/clerk-react";

import PrivateRoute from "@/modules/common/components/PrivateRoute.tsx";
import Dashboard from "@/modules/common/components/Dashboard/Dashboard.tsx";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { loadQuery, useRelayEnvironment } from "react-relay";
import { DashboardQuery } from "@/modules/common/components/Dashboard/DashboardQuery.ts";
import CompleteProfilePage from "@/modules/completeProfile/components/CompleteProfilePage.tsx";
import { CompleteProfilePageQuery } from "@/modules/completeProfile/graphql/CompleteProfilePageQuery.ts";
import { SignInPage, SignUpPage } from "@/modules/auth";
import { RoomsPage } from "@/modules/rooms";
import { NotFound } from "@/modules/common";
import ErrorFallback from "@/modules/common/components/ErrorFallback.tsx";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

import { useFlags } from "flagsmith/react";
import { FeatureFlag } from "@/modules/common/constants.ts";
import MaintenanceMode from "@/modules/common/components/MaintenanceMode.tsx";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const RoutingProvider = () => {
  const auth = useAuth();
  const { user } = useUser();
  const environment = useRelayEnvironment();
  const flags = useFlags([FeatureFlag.MAINTENANCE_MODE]); // only causes re-render if specified flag values / traits change
  const isMaintenanceModeOn = flags.maintenance_mode.enabled;

  useEffect(() => {
    if (auth.isLoaded && user) {
      Sentry.setUser({
        id: user.id,
        email: user.primaryEmailAddress?.emailAddress,
      });
    }

    return () => {
      Sentry.setUser(null);
    };
  }, [auth.isLoaded, user]);

  if (!auth.isLoaded) {
    return null;
  }

  if (isMaintenanceModeOn) {
    return <MaintenanceMode />;
  }

  const router = sentryCreateBrowserRouter([
    {
      path: "/sign-in",
      element: <SignInPage />,
      errorElement: <ErrorFallback />,
    },
    {
      path: "/sign-up",
      element: <SignUpPage />,
      errorElement: <ErrorFallback />,
    },
    {
      path: "/complete-profile",
      element: (
        <PrivateRoute>
          <CompleteProfilePage />
        </PrivateRoute>
      ),
      loader: async () => {
        return Promise.resolve(
          loadQuery(environment, CompleteProfilePageQuery, {}),
        );
      },
      errorElement: <ErrorFallback />,
    },
    {
      path: "/",
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      ),
      errorElement: <ErrorFallback />,
      loader: async () => {
        return Promise.resolve(loadQuery(environment, DashboardQuery, {}));
      },
      children: [
        { index: true, element: <Navigate to="/rooms" replace /> },
        {
          path: "rooms",
          element: <RoomsPage />,
          errorElement: <ErrorFallback />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default RoutingProvider;
