import { graphql } from "relay-runtime";

export const CompleteProfilePageQuery = graphql`
  query CompleteProfilePageQuery {
    viewer {
      profile {
        isProfileComplete
        ...CompleteProfileFormFragment_profile
      }
    }
  }
`;
