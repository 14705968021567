import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/browser";
import Section from "@/modules/settings/components/Section.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { Button } from "@/components/ui/button.tsx";
import { graphql } from "relay-runtime";
import { useFragment } from "react-relay";
import { useDashboardContext } from "@/modules/common/providers/DashboardContext.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { FeedbackFormFragment_profile$key } from "@/__generated__/FeedbackFormFragment_profile.graphql.ts";

const FeedbackFormFragment_profile = graphql`
  fragment FeedbackFormFragment_profile on User {
    name
  }
`;

const FeedbackForm = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const dashboardData = useDashboardContext();
  const profile = useFragment(
    FeedbackFormFragment_profile,
    dashboardData.data.viewer
      ?.profile as unknown as FeedbackFormFragment_profile$key,
  );

  const feedbackSchema = z.object({
    name: z.string().min(1, t("Name is required")).max(30),
    email: z
      .string()
      .min(1, t("Email is required"))
      .email({ message: t("Invalid email address") }),
    feedback: z.string().min(1, t("Feedback is required")),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: profile.name || "",
      email: user?.primaryEmailAddress?.emailAddress || "",
      feedback: "",
    },
    resolver: zodResolver(feedbackSchema),
  });

  const onSubmit = (data: z.infer<typeof feedbackSchema>) => {
    Sentry.captureFeedback({
      name: data.name.trim(),
      email: data.email.trim(),
      message: data.feedback.trim(),
    });
    reset();
    toast({
      title: t("Feedback Received! 🙌"),
      description: t(
        "Thank you for your feedback! We appreciate your input and will use it to improve our app.",
      ),
    });
  };

  return (
    <Section
      title={"Send us your feedback"}
      content={
        <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-2">
            <Label htmlFor="name">{t("Name")}</Label>
            <div>
              <Input
                id="name"
                placeholder={t("Enter your name")}
                maxLength={30}
                {...register("name")}
              />
              {errors?.name?.message && (
                <span className="text-red-500 text-sm">
                  {t(errors.name.message)}
                </span>
              )}
            </div>
          </div>
          <div className="grid gap-2">
            <Label htmlFor="email">{t("Email")}</Label>
            <div>
              <Input
                id="email"
                type="email"
                placeholder={t("Enter your email")}
                {...register("email")}
              />
              {errors?.email?.message && (
                <span className="text-red-500 text-sm">
                  {t(errors.email.message)}
                </span>
              )}
            </div>
          </div>
          <div className="grid gap-2">
            <Label htmlFor="feedback">{t("Feedback")}</Label>
            <div>
              <Textarea
                id="feedback"
                placeholder={t("Let us know what you think")}
                {...register("feedback")}
                className="min-h-[150px]"
              />
              {errors?.feedback?.message && (
                <span className="text-red-500 text-sm">
                  {t(errors.feedback.message)}
                </span>
              )}
            </div>
          </div>
          <Button type="submit" className="w-full">
            {t("Submit Feedback")}
          </Button>
        </form>
      }
    />
  );
};

export default FeedbackForm;
