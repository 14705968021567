import { Label } from "@/components/ui/label.tsx";
import { Field, FieldProps } from "formik";
import { Input } from "@/components/ui/input.tsx";
import { useTranslation } from "react-i18next";
import { CompleteProfileField } from "@/modules/completeProfile/types.ts";

const UsernameField = ({ tag }: { tag: string }) => {
  const { t } = useTranslation();

  return (
    <div className="grid w-full max-w-sm items-center gap-1.5">
      <Label htmlFor="username">{t("Username")}</Label>
      <Field name={CompleteProfileField.Username}>
        {({ field, meta }: FieldProps) => (
          <>
            <div className="flex items-center gap-4">
              <Input
                type="text"
                id="username"
                placeholder={t("Username")}
                maxLength={30}
                className={"border"}
                {...field}
              />
              <div className="ltr:flex rtl:flex-row-reverse text-sm">
                <span className="text-gray-500 font-bold">#</span>
                <span className="text-gray-500 font-bold">{tag}</span>
              </div>
            </div>
            {meta.error && meta.touched && (
              <small className="text-sm font-medium leading-none text-red-500">
                {t(meta.error)}
              </small>
            )}
          </>
        )}
      </Field>
    </div>
  );
};

export default UsernameField;
