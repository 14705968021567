import { useTranslation } from "react-i18next";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group.tsx";
import { Label } from "@/components/ui/label.tsx";
import { SupportedLanguages } from "@/modules/language/consants.ts";
import Section from "@/modules/settings/components/Section.tsx";

const Language = () => {
  const { i18n } = useTranslation();

  return (
    <Section
      title={"Choose Your Language"}
      content={
        <RadioGroup
          defaultValue={i18n.language}
          onValueChange={(newLanguage) => i18n.changeLanguage(newLanguage)}
          className={"gap-3"}
        >
          {Object.values(SupportedLanguages).map((lng) => (
            <div key={lng.value} className="flex items-center gap-2 mt-">
              <RadioGroupItem value={lng.value} id={lng.value} />
              <Label htmlFor={lng.nativeName}>{lng.nativeName}</Label>
            </div>
          ))}
        </RadioGroup>
      }
    />
  );
};

export default Language;
