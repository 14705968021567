/**
 * @generated SignedSource<<904c2bbc866998b1333b7d94738ef3d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditProfileFormFragment_profile$data = {
  readonly avatar: string | null | undefined;
  readonly bio: string | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "EditProfileFormFragment_profile";
};
export type EditProfileFormFragment_profile$key = {
  readonly " $data"?: EditProfileFormFragment_profile$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditProfileFormFragment_profile">;
};

import EditProfileRefetchQuery_graphql from './EditProfileRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": EditProfileRefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "EditProfileFormFragment_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "dacb32caf9fa2e20b8673da035709b6d";

export default node;
