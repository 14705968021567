import { graphql } from "relay-runtime";
import {
  CompleteProfileField,
  FormValues,
} from "@/modules/completeProfile/types.ts";
import { validationSchema } from "@/modules/completeProfile/constants.ts";
import { Form, Formik } from "formik";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import NameField from "@/modules/completeProfile/components/NameField.tsx";
import UsernameField from "@/modules/completeProfile/components/UsernameField.tsx";
import BirthdayField from "@/modules/completeProfile/components/BirthdayField.tsx";
import { Button } from "@/components/ui/button.tsx";
import { ChevronLeft, ChevronRight, Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@clerk/clerk-react";
import { useFragment, useMutation } from "react-relay";
import { CompleteProfileMutation } from "@/modules/completeProfile/graphql/CompleteProfileMutation.ts";
import { toast } from "@/components/ui/use-toast.ts";
import type { CompleteProfileMutation as CompleteProfileMutationType } from "@/__generated__/CompleteProfileMutation.graphql.ts";
import { CompleteProfileMutation$data } from "@/__generated__/CompleteProfileMutation.graphql.ts";
import { useNavigate } from "react-router-dom";
import { CompleteProfileFormFragment_profile$key } from "@/__generated__/CompleteProfileFormFragment_profile.graphql.ts";

const CompleteProfileFormFragment_profile = graphql`
  fragment CompleteProfileFormFragment_profile on User {
    tag
    isProfileComplete
  }
`;

export const CompleteProfileForm = ({
  profile,
}: {
  profile: CompleteProfileFormFragment_profile$key;
}) => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { t, i18n } = useTranslation();
  const userData = useFragment(CompleteProfileFormFragment_profile, profile);

  const [completeProfile, isCompleteProfileLoading] =
    useMutation<CompleteProfileMutationType>(CompleteProfileMutation);

  const initialValues: FormValues = {
    [CompleteProfileField.Name]: "",
    [CompleteProfileField.Username]: "",
    [CompleteProfileField.Birthday]: undefined,
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (values.birthday) {
          completeProfile({
            variables: {
              input: {
                name: values.name.trim(),
                username: values.username.trim(),
                birthday: new Date(values.birthday).toISOString(),
              },
            },
            onCompleted: (response: CompleteProfileMutation$data) => {
              if (response.completeProfile?.message === "Error") {
                toast({
                  variant: "destructive",
                  title: t(
                    response.completeProfile.message ||
                      "Something went wrong, try again later",
                  ),
                });
              }
              if (response.completeProfile?.data) {
                if (response.completeProfile?.data.isProfileComplete === true) {
                  toast({
                    title: t("Welcome to Menaverse.gg 🎉"),
                  });
                  navigate("/rooms");
                }
              }
            },
            onError: () => {
              toast({
                variant: "destructive",
                title: t("Something went wrong, try again later"),
              });
            },
          });
        }
      }}
    >
      {({ handleSubmit }) => (
        <Form
          className="h-screen flex justify-center items-center bg-secondary"
          onSubmit={handleSubmit}
        >
          <Card>
            <CardHeader>
              <CardTitle className="text-center">
                {t("Complete your Profile")}
              </CardTitle>
              <CardDescription className="text-center">
                {t("One last step before you start gaming with friends 🎉")}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-4">
                <NameField />
                <UsernameField tag={userData.tag} />
                <BirthdayField />
              </div>
            </CardContent>
            <CardFooter className="flex justify-between gap-3">
              <Button
                type={"button"}
                variant="secondary"
                className="w-full"
                onClick={() => signOut()}
              >
                {i18n.dir() === "ltr" ? (
                  <ChevronLeft className="ltr:mr-2 rtl:ml-2 h-4 w-4" />
                ) : (
                  <ChevronRight className="ltr:mr-2 rtl:ml-2 h-4 w-4" />
                )}

                {t("Go back")}
              </Button>
              <Button
                variant="default"
                className="w-full"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={isCompleteProfileLoading}
              >
                {isCompleteProfileLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    {t("Completing your profile")}
                  </>
                ) : (
                  t("Complete Profile")
                )}
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
