import { Label } from "@/components/ui/label.tsx";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar.tsx";
import ImagePicker from "@/modules/common/components/ImagePicker.tsx";
import { Button } from "@/components/ui/button.tsx";
import { EditProfileField } from "@/modules/profile/editProfile/constants.ts";
import { Field, FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { displayNameInitials } from "@/modules/common/utils.ts";
import { Trash2 } from "lucide-react";

const AvatarField = () => {
  const { t } = useTranslation();

  return (
    <Field name={EditProfileField.Avatar}>
      {({ field, form }: FieldProps) => (
        <div className={"flex items-center justify-between gap-6"}>
          <Label htmlFor="name" className="text-right">
            {t("Avatar")}
          </Label>
          <div className={"flex-1 flex items-center gap-4"}>
            <Avatar
              className={
                "w-14 h-14 flex-shrink-0 shadow-2xl rounded-full border"
              }
            >
              <AvatarImage src={field.value || undefined} />
              <AvatarFallback>
                {displayNameInitials(form.values[EditProfileField.Name])}
              </AvatarFallback>
            </Avatar>
            <div className={"flex"}>
              <ImagePicker
                title={"Change avatar"}
                onImageSelect={(image) =>
                  form.setFieldValue(EditProfileField.Avatar, image)
                }
              />
              {field.value && (
                <Button
                  type={"button"}
                  variant={"link"}
                  onClick={() =>
                    form.setFieldValue(EditProfileField.Avatar, null)
                  }
                >
                  <Trash2 className={"mx-2 h-4 w-4 md:hidden"} />
                  <div className={"hidden md:block"}>{t("Remove")}</div>
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Field>
  );
};

export default AvatarField;
