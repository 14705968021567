const RoomsPage = () => {
  return (
    <div className={"container flex items-center justify-center h-full"}>
      Coming soon...
      <button onClick={() => console.error("Something test went wrong")}>
        Break the world
      </button>
    </div>
  );
};

export default RoomsPage;
