import { graphql } from "relay-runtime";

export const DashboardQuery = graphql`
  query DashboardQuery {
    viewer {
      profile {
        id
        isProfileComplete
        ...UserButtonFragment_profile
        ...EditProfileFormFragment_profile
        ...FeedbackFormFragment_profile
      }
    }
  }
`;
