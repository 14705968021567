/**
 * @generated SignedSource<<e45e0a0b962348c5d9f8c153f4fa0c56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompleteProfileFormFragment_profile$data = {
  readonly isProfileComplete: boolean;
  readonly tag: string;
  readonly " $fragmentType": "CompleteProfileFormFragment_profile";
};
export type CompleteProfileFormFragment_profile$key = {
  readonly " $data"?: CompleteProfileFormFragment_profile$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompleteProfileFormFragment_profile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompleteProfileFormFragment_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tag",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isProfileComplete",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "fb0769e44343e8258de04f6e4f47d548";

export default node;
