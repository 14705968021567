import { useTranslation } from "react-i18next";
import DeleteAccount from "@/modules/settings/components/DeleteAccount.tsx";
import Section from "@/modules/settings/components/Section.tsx";

const DangerZone = () => {
  const { t } = useTranslation();
  return (
    <Section
      title={"Danger zone"}
      content={
        <div className="rounded-md flex flex-col gap-3 border-2 bg-background p-4">
          <div className="text-sm">{t("Delete account")}</div>
          <DeleteAccount />
        </div>
      }
    />
  );
};

export default DangerZone;
