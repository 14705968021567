import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import Backend from "i18next-locize-backend";

const locizeOptions = {
  projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  apiKey: import.meta.env.VITE_LOCIZE_API_KEY,
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: import.meta.env.DEV,
    fallbackLng: "en",
    returnNull: false,
    interpolation: {
      escapeValue: false,
    },
    backend: locizeOptions,
    saveMissing: import.meta.env.DEV,
  })
  .then(
    () =>
      import.meta.env.DEV && console.log("🌎 Translations successfully loaded"),
  );

export default i18n;
