import dark from "../../../assets/sign-in-marketing-dark.png";
import light from "../../../assets/sign-in-marketing.png";
import { useAppliedTheme } from "@/modules/appearance/hooks.ts";

const AuthPageMarketing = () => {
  const theme = useAppliedTheme();

  return (
    <img
      src={theme === "light" ? light : dark}
      alt={"logo"}
      className={"max-h-[700px] w-auto"}
    />
  );
};

export default AuthPageMarketing;
