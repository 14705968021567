/**
 * @generated SignedSource<<87aa724568979fd19e0e70299217aff0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteAccountMutation$variables = Record<PropertyKey, never>;
export type DeleteAccountMutation$data = {
  readonly deleteAccount: {
    readonly data?: boolean;
    readonly message?: string | null | undefined;
  } | null | undefined;
};
export type DeleteAccountMutation = {
  response: DeleteAccountMutation$data;
  variables: DeleteAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "Error",
  "abstractKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "data",
      "storageKey": null
    }
  ],
  "type": "MutationDeleteAccountSuccess",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteAccount",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeleteAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "deleteAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c98ea7dfd782b4ccef2255f794e3eb97",
    "id": null,
    "metadata": {},
    "name": "DeleteAccountMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteAccountMutation {\n  deleteAccount {\n    __typename\n    ... on Error {\n      message\n    }\n    ... on MutationDeleteAccountSuccess {\n      data\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "da69ecbac111ba538983d2f3ee4f2fd5";

export default node;
