import { Label } from "@/components/ui/label.tsx";
import { Field, FieldProps } from "formik";
import { Input } from "@/components/ui/input.tsx";
import { CompleteProfileField } from "@/modules/completeProfile/types.ts";
import { useTranslation } from "react-i18next";

const NameField = () => {
  const { t } = useTranslation();

  return (
    <div className="grid w-full max-w-sm items-center gap-1.5">
      <Label htmlFor="name">{t("Display name")}</Label>
      <Field name={CompleteProfileField.Name}>
        {({ field, meta }: FieldProps) => (
          <>
            <Input
              type={"text"}
              maxLength={30}
              placeholder={t("Display name")}
              className={"border"}
              {...field}
            />
            {meta.error && (
              <small className="text-sm font-medium leading-none text-red-500">
                {t(meta.error)}
              </small>
            )}
          </>
        )}
      </Field>
    </div>
  );
};

export default NameField;
