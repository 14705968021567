import { SignUp } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";
import { useAppliedTheme } from "@/modules/appearance/hooks.ts";

const SignUpPage = () => {
  const theme = useAppliedTheme();

  return (
    <div
      dir={"ltr"}
      className={"h-full w-full flex align-middle justify-center items-center"}
    >
      <SignUp
        signInUrl={"sign-in"}
        appearance={{
          baseTheme: theme === "dark" ? dark : undefined,
          variables: {
            colorPrimary: "#ff00bf",
          },
        }}
      />
    </div>
  );
};

export default SignUpPage;
