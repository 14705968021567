import { graphql } from "relay-runtime";

export const DeleteAccountMutation = graphql`
  mutation DeleteAccountMutation {
    deleteAccount {
      ... on Error {
        message
      }
      ... on MutationDeleteAccountSuccess {
        data
      }
    }
  }
`;
