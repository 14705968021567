/**
 * @generated SignedSource<<0f2daa7616a63986daf7e477d704f78b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ImageType = "AVATAR" | "BANNER" | "%future added value";
export type ImagePickerUploadImageMutation$variables = {
  image: string;
  type: ImageType;
};
export type ImagePickerUploadImageMutation$data = {
  readonly uploadImage: {
    readonly __typename: "Error";
    readonly message: string | null | undefined;
  } | {
    readonly __typename: "MutationUploadImageSuccess";
    readonly data: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type ImagePickerUploadImageMutation = {
  response: ImagePickerUploadImageMutation$data;
  variables: ImagePickerUploadImageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "image"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "image",
        "variableName": "image"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "uploadImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "Error",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "data",
            "storageKey": null
          }
        ],
        "type": "MutationUploadImageSuccess",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImagePickerUploadImageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImagePickerUploadImageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f992beb3fcc8c72482de3e970df84ee6",
    "id": null,
    "metadata": {},
    "name": "ImagePickerUploadImageMutation",
    "operationKind": "mutation",
    "text": "mutation ImagePickerUploadImageMutation(\n  $image: String!\n  $type: ImageType!\n) {\n  uploadImage(image: $image, type: $type) {\n    __typename\n    ... on Error {\n      message\n    }\n    ... on MutationUploadImageSuccess {\n      data\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "24897a38b9e8b6a0a4d27dd130d05dc6";

export default node;
