import { useTranslation } from "react-i18next";
import { Theme, useTheme } from "@/modules/common/providers/ThemeProvider.tsx";
import Section from "@/modules/settings/components/Section.tsx";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group.tsx";
import { AppearanceOptions } from "@/modules/settings/constants.ts";
import { Label } from "@/components/ui/label.tsx";

const Appearance = () => {
  const { t } = useTranslation();
  const { setTheme, theme } = useTheme();

  return (
    <Section
      title={"Choose your theme"}
      content={
        <RadioGroup
          defaultValue={theme}
          onValueChange={(newTheme: Theme) => setTheme(newTheme)}
          className={"gap-3"}
        >
          {AppearanceOptions.map(({ value, label }) => (
            <div key={value} className="flex items-center gap-2">
              <RadioGroupItem value={value} id={value} />
              <Label htmlFor={value}>{t(label)}</Label>
            </div>
          ))}
        </RadioGroup>
      }
    />
  );
};

export default Appearance;
