import { graphql } from "relay-runtime";
import { useDashboardContext } from "@/modules/common/providers/DashboardContext.tsx";
import { useMutation, useRefetchableFragment } from "react-relay";
import { useTranslation } from "react-i18next";
import {
  EditProfileField,
  FormValues,
  validationSchema,
} from "@/modules/profile/editProfile/constants.ts";
import { Form, Formik } from "formik";
import type { EditProfileFormMutation as EditProfileFormMutationType } from "@/__generated__/EditProfileFormMutation.graphql.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { useEffect } from "react";
import { EditProfileFormFragment_profile$key } from "@/__generated__/EditProfileFormFragment_profile.graphql.ts";
import Section from "@/modules/settings/components/Section.tsx";
import NameField from "@/modules/profile/editProfile/components/NameField.tsx";
import AvatarField from "@/modules/profile/editProfile/components/AvatarField.tsx";
import BioField from "@/modules/profile/editProfile/components/BioField.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Loader2 } from "lucide-react";

const EditProfileFormFragment = graphql`
  fragment EditProfileFormFragment_profile on User
  @refetchable(queryName: "EditProfileRefetchQuery") {
    id
    name
    avatar
    bio
  }
`;

const EditProfileFormMutation = graphql`
  mutation EditProfileFormMutation($input: EditProfileInput!) {
    editProfile(input: $input) {
      __typename
      ... on MutationEditProfileSuccess {
        __typename
        data {
          ...EditProfileFormFragment_profile
        }
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;

const EditProfileForm = () => {
  const { t } = useTranslation();
  const { data } = useDashboardContext();
  const [user, refetch] = useRefetchableFragment(
    EditProfileFormFragment,
    data.viewer?.profile as unknown as EditProfileFormFragment_profile$key,
  );

  const [editProfile, isEditProfileLoading] =
    useMutation<EditProfileFormMutationType>(EditProfileFormMutation);

  useEffect(() => {
    refetch({}, { fetchPolicy: "store-and-network" });
  }, [refetch]);

  return (
    <Formik
      initialValues={
        {
          [EditProfileField.Name]: user.name,
          [EditProfileField.Avatar]: user.avatar,
          [EditProfileField.Bio]: user.bio,
        } as FormValues
      }
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        editProfile({
          variables: {
            input: {
              name: values.name.trim(),
              avatar: values.avatar,
              bio: values.bio.trim(),
            },
          },
          onCompleted: (data) => {
            if (data.editProfile?.__typename === "Error") {
              toast({
                variant: "destructive",
                title: t(
                  data.editProfile?.message ||
                    "Something went wrong, try again later",
                ),
              });
              return;
            }
            if (data.editProfile?.__typename === "MutationEditProfileSuccess") {
              toast({
                title: t("Profile has been updated 🎉"),
              });
            }
          },
          onError: () => {
            toast({
              variant: "destructive",
              title: t("Something went wrong"),
            });
          },
        });
      }}
    >
      {({ handleSubmit, dirty, resetForm, errors }) => (
        <Form onSubmit={handleSubmit} className={"flex-1 h-full flex flex-col"}>
          <div className={"flex-1 h-full flex flex-col"}>
            <Section
              title={"Edit your profile"}
              content={
                <>
                  <div className=" flex-1 flex flex-col gap-6">
                    <NameField />
                    <AvatarField />
                    <BioField />
                  </div>
                  {dirty && (
                    <div
                      className={
                        "drop-shadow-lg flex flex-row justify-between items-center rounded-md bg-background p-2"
                      }
                    >
                      <div
                        className={
                          "text-secondary-foreground text-xs sm:text-sm mx-3"
                        }
                      >
                        {t("You have unsaved changes.")}
                      </div>
                      <div className={"flex"}>
                        <Button
                          variant={"ghost"}
                          onClick={() => resetForm()}
                          type={"reset"}
                          size={"sm"}
                        >
                          {t("Reset")}
                        </Button>
                        <Button
                          size={"sm"}
                          disabled={
                            Boolean(Object.keys(errors).length) ||
                            isEditProfileLoading
                          }
                          className={"flex-1"}
                          onClick={() => handleSubmit()}
                          type={"submit"}
                        >
                          <>
                            {isEditProfileLoading ? (
                              <Loader2 className="mx-2 h-4 w-4 animate-spin" />
                            ) : (
                              t("Save changes")
                            )}
                          </>
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditProfileForm;
