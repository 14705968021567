import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import LogoutDialog from "@/modules/auth/components/LogoutDialog.tsx";
import { Separator } from "@/components/ui/separator.tsx";
import SettingsModal from "@/modules/settings/components/SettingsModal.tsx";
import { graphql } from "relay-runtime";
import { useDashboardContext } from "@/modules/common/providers/DashboardContext.tsx";
import { useFragment } from "react-relay";
import { UserButtonFragment_profile$key } from "@/__generated__/UserButtonFragment_profile.graphql.ts";
import { displayNameInitials } from "@/modules/common/utils.ts";

const UserButtonFragment_profile = graphql`
  fragment UserButtonFragment_profile on User {
    name
    fullUsername
    avatar
  }
`;

const UserButton = () => {
  const { data } = useDashboardContext();
  const user = useFragment(
    UserButtonFragment_profile,
    data.viewer?.profile as unknown as UserButtonFragment_profile$key,
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Avatar className={"w-10 h-10 cursor-pointer"}>
          <AvatarImage src={user.avatar || undefined} />
          <AvatarFallback>{displayNameInitials(user.name)}</AvatarFallback>
        </Avatar>
      </PopoverTrigger>
      <PopoverContent
        className="w-60 py-2 px-2"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <div className={"flex flex-col"}>
          <div className={"w-full flex gap-3 items-center p-2"}>
            <Avatar className={"w-10 h-10"}>
              <AvatarImage
                src={user.avatar || undefined}
                className={"border"}
              />
              <AvatarFallback>{displayNameInitials(user.name)}</AvatarFallback>
            </Avatar>
            <div className={"flex-1"}>
              <div className={"text-sm font-bold"}>{user.name}</div>
              <div className={"text-xs text-gray-500"}>{user.fullUsername}</div>
            </div>
          </div>
          <Separator className="my-2" />
          <SettingsModal />
          <Separator className="my-2" />
          <LogoutDialog />
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default UserButton;
