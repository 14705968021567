/**
 * @generated SignedSource<<9d6caec2b4b8c2016b49e5b05ce7df3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardQuery$variables = Record<PropertyKey, never>;
export type DashboardQuery$data = {
  readonly viewer: {
    readonly profile: {
      readonly id: string;
      readonly isProfileComplete: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"EditProfileFormFragment_profile" | "FeedbackFormFragment_profile" | "UserButtonFragment_profile">;
    } | null | undefined;
  } | null | undefined;
};
export type DashboardQuery = {
  response: DashboardQuery$data;
  variables: DashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isProfileComplete",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserButtonFragment_profile"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditProfileFormFragment_profile"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FeedbackFormFragment_profile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullUsername",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bio",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e25570c9fed3494713ca0fbfe1ba75d5",
    "id": null,
    "metadata": {},
    "name": "DashboardQuery",
    "operationKind": "query",
    "text": "query DashboardQuery {\n  viewer {\n    profile {\n      id\n      isProfileComplete\n      ...UserButtonFragment_profile\n      ...EditProfileFormFragment_profile\n      ...FeedbackFormFragment_profile\n    }\n  }\n}\n\nfragment EditProfileFormFragment_profile on User {\n  id\n  name\n  avatar\n  bio\n}\n\nfragment FeedbackFormFragment_profile on User {\n  name\n}\n\nfragment UserButtonFragment_profile on User {\n  name\n  fullUsername\n  avatar\n}\n"
  }
};
})();

(node as any).hash = "586bade161bb8d8f7bbdc796bbfe4255";

export default node;
