import React, { createContext, ReactNode, useContext } from "react";
import type { DashboardQuery as DashboardQueryType } from "@/__generated__/DashboardQuery.graphql.ts";

type DashboardContextType = {
  data: DashboardQueryType["response"];
};

const DashboardContext = createContext<DashboardContextType | undefined>(
  undefined,
);

// eslint-disable-next-line react-refresh/only-export-components
export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider",
    );
  }
  return context;
};

export const DashboardProvider: React.FC<{
  value: DashboardContextType;
  children: ReactNode;
}> = ({ value, children }) => {
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
