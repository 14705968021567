import React, { Suspense, useEffect } from "react";
import { ThemeProvider } from "@/modules/common/providers/ThemeProvider";
import { THEME_STORAGE_KEY } from "@/modules/common/constants";
import { useTranslation } from "react-i18next";
import { DirectionProvider } from "@radix-ui/react-direction";
import { Toaster } from "@/components/ui/toaster";
import RoutingProvider from "@/modules/common/providers/RoutingProvider";
import Loading from "@/modules/common/components/Loading";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const direction = i18n.language !== "ar" ? "ltr" : "rtl";

  useEffect(() => {
    const rootHtml = document.getElementById("root");
    if (rootHtml) {
      rootHtml.setAttribute("lang", i18n.language);
      rootHtml.setAttribute("dir", direction);
    }
  }, [direction, i18n.language]);

  return (
    <FlagsmithProvider
      options={{
        environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID,
      }}
      flagsmith={flagsmith}
    >
      <ThemeProvider defaultTheme="system" storageKey={THEME_STORAGE_KEY}>
        <DirectionProvider dir={direction}>
          <Suspense fallback={<Loading />}>
            <Toaster />
            <RoutingProvider />
          </Suspense>
        </DirectionProvider>
      </ThemeProvider>
    </FlagsmithProvider>
  );
};

export default App;
