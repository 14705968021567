import { useTranslation } from "react-i18next";
import { useAppliedTheme } from "@/modules/appearance/hooks.ts";

const MaintenanceMode = () => {
  const theme = useAppliedTheme();
  const { t } = useTranslation();

  return (
    <div className={"h-full bg-secondary flex  justify-center items-center"}>
      <div
        className={
          "bg-background rounded-lg p-5 border flex flex-col items-center max-w-md"
        }
      >
        <div className={"text-3xl my-2"}>🔧</div>
        <div className={"font-bold text-xl text-center"}>
          {t("We'll be back soon")}
        </div>
        <div className={"text-gray-500 mb-4 text-center"}>
          {t(
            "Our website is currently undergoing scheduled maintenance. We apologize for the inconvenience and appreciate your patience.",
          )}
        </div>
        <div className={"w-full flex items-center justify-center"}>
          <iframe
            src={`https://menaversegg.instatus.com/embed-status/2ee8c77e/${theme === "dark" ? "dark" : "light"}-md`}
            width="235"
            height="61"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceMode;
