import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";

import { Settings2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { SettingsTab } from "@/modules/settings/constants.ts";
import Language from "@/modules/settings/components/Language.tsx";
import Appearance from "@/modules/settings/components/Appearance.tsx";
import { useState } from "react";
import DangerZone from "@/modules/settings/components/DangerZone.tsx";
import { EditProfileForm } from "@/modules/profile";
import FeedbackForm from "@/modules/Feedback/components/FeedbackForm.tsx";
import { useAppliedTheme } from "@/modules/appearance/hooks.ts";
import SettingsNavigation from "@/modules/settings/components/SettingsNavigation.tsx";

const SettingsModal = () => {
  const theme = useAppliedTheme();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(SettingsTab.PROFILE);

  const components = {
    [SettingsTab.PROFILE]: () => <EditProfileForm />,
    [SettingsTab.APPEARANCE]: () => <Appearance />,
    [SettingsTab.LANGUAGE]: () => <Language />,
    [SettingsTab.FEEDBACK]: () => <FeedbackForm />,
    [SettingsTab.DANGER_ZONE]: () => <DangerZone />,
  };

  const SelectedComponent = components[activeTab];

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant={"ghost"}
          className={"w-full flex justify-start gap-3 "}
        >
          <Settings2 className="rtl:ml-2 ltr:mr-2 h-4 w-4" />
          {t("Settings")}
        </Button>
      </DialogTrigger>
      <DialogContent className="w-screen h-screen p-0 md:h-[80vh] md:max-w-[800px] sm:rounded-lg overflow-hidden">
        <div className="flex flex-col sm:flex-row bg-secondary">
          <div className="md:w-64 sm:w-64 bg-muted/40 flex flex-col">
            <DialogHeader className="px-4 py-3 md:px-6 md:py-4 bg-background">
              <DialogTitle className="text-xl">{t("Settings")}</DialogTitle>
            </DialogHeader>
            <SettingsNavigation
              activeTab={activeTab}
              onTabChange={(tab) => setActiveTab(tab)}
            />
            <div
              className={
                "hidden sm:flex items-center justify-center content-center px-3 py-2 bg-background"
              }
            >
              <iframe
                src={`https://menaversegg.instatus.com/embed-status/2ee8c77e/${theme === "dark" ? "dark" : "light"}-sm`}
                width="236"
                height="41"
                style={{
                  border: "none",
                  fontSize: "small",
                }}
              ></iframe>
            </div>
          </div>
          <div className="flex-1 p-2 sm:p-6 overflow-y-auto">
            <SelectedComponent />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsModal;
