import { ReactNode, useMemo } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { useAuth } from "@clerk/clerk-react";
import { Environment, Network, RecordSource, Store } from "relay-runtime";

const RelayProviderWrapper = ({ children }: { children: ReactNode }) => {
  const { getToken } = useAuth();

  const environment = useMemo(() => {
    return new Environment({
      network: Network.create(async (request, variables) => {
        const resp = await fetch(`${import.meta.env.VITE_API_URL}/graphql`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            Accept:
              "application/graphql-response+json; charset=utf-8, application/json; charset=utf-8",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: request.text, // <-- The GraphQL document composed by Relay
            variables,
          }),
        });

        return await resp.json();
      }),
      store: new Store(new RecordSource()),
    });
  }, [getToken]);

  return (
    <RelayEnvironmentProvider environment={environment}>
      {children}
    </RelayEnvironmentProvider>
  );
};

export default RelayProviderWrapper;
